.header {
  background-color: #f8f8f8;
  height: 60px;
  padding: 5px 10px;
  position: fixed;
  box-shadow: unset;
  top: 0;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
}

.header .left {
  text-align: left;
  display: inline-block;
  /* width: 50%; */
}

.header .left .header-logo {
  display: inline-block;
}

.header .right {
  text-align: right;
  display: inline-block;
  /* width: 50%; */
}

.header .right .healthcare-logo {
  display: inline-block;
  /* padding: 0 15px 15px 15px; */
}

.header .right .notifications-logo {
  display: inline-block;
  padding: 0 15px 15px 15px;
}

.header .right .profile-logo {
  display: inline-block;
  /* padding: 0 0 15px 0; */
}

.header .right .notifications-logo img {
  width: 24px;
  height: 26px;
}

.avatar {
  width: 24px;
  height: 24px;
}

.logo {
  text-align: left;
  height: 40px;
  /* padding-bottom: 20px; */
  box-sizing: border-box;
}

.dashboard-logo{
  height:90px;
}

.hlogo {
  width: 106px;
  height: 32px;
}

.dropdown-item a {
  color: #000000 !important;
  text-decoration: none !important;
}

.drop-rel {
  position: relative;
}

.UpperArrow {
  position: absolute;
  top: -25px;
  left: 15px;
  height: 22px;
}

.UpperArrowright {
  position: absolute;
  top: -25px;
  right: 15px;
  height: 22px;
}

@media screen and (min-width: 360px) {
  .header {
    padding: 10px;
  }

  /* .header .left {
    width: 40%;
  }
  .header .right {
    width: 60%;
  } */
  .header .left .header-logo {
    padding: 0;
  }

  .header .left .clinical {
    display: none;
  }

  .header .left .business-developement {
    display: none;
  }

  .header .left .admin {
    display: none;
  }

  .header .left .experimental {
    display: none;
  }

  .header .right .healthcare-logo {
    display: inline-block;
    padding: 0;
  }

  .header .right .notifications-logo {
    display: inline-block;
    padding: 0 5px;
  }

  .header .right .profile-logo {
    display: inline-block;
    /* padding: 0 0 0 0; */
  }
}

@media screen and (min-width: 768px) {
  .header {
    padding: 10px 20px;
  }

  /* .header .left {
    width: 65%;
  }
  .header .right {
    width: 35%;
  } */
  /* .header .left .header-logo {
    padding: 0 10px 15px 0;
  } */

  .header .left .clinical {
    display: inline-block;
    /* padding: 0 5px 15px 5px; */
  }

  .header .left .business-developement {
    display: inline-block;
    /* padding: 0 5px 15px 5px; */
  }

  .header .left .admin {
    display: inline-block;
    /* padding: 0 15px 15px 5px; */
  }

  .header .left .experimental {
    display: inline-block;
    /* padding: 0 0 15px 5px; */
  }

  .header .right .healthcare-logo {
    display: inline-block;
    /* padding: 0 20px 15px 10px; */
  }

  .header .right .notifications-logo {
    display: inline-block;
    padding: 0 10px 15px 10px;
  }

  .header .right .profile-logo {
    display: inline-block;
    /* padding: 0 0 15px 5px; */
  }
}

@media screen and (min-width: 830px) {
  .header {
    padding: 10px 20px;
  }

  /* .header .left {
    width: 65%;
  }
  .header .right {
    width: 35%;
  } */
  /* .header .left .header-logo {
    padding: 0 15px 15px 0;
  } */

  .header .left .clinical {
    display: inline-block;
    /* padding: 0 10px 15px 10px; */
  }

  .header .left .business-developement {
    display: inline-block;
    /* padding: 0 10px 15px 10px; */
  }

  .header .left .admin {
    display: inline-block;
    /* padding: 0 15px 15px 10px; */
  }

  .header .left .experimental {
    display: inline-block;
    /* padding: 0 0 15px 10px; */
  }

  /* .header .right .healthcare-logo {
    padding: 0 15px 15px 15px;
  } */

  .header .right .notifications-logo {
    padding: 0 15px 15px 15px;
  }

  /* .header .right .profile-logo {
    padding: 0 0 15px 0;
  } */
}

@media screen and (min-width: 1170px) {
  .header {
    padding: 10px 30px;
    z-index: 1090;
  }

  /* .header .left {
    width: 60%;
  }
  .header .right {
    width: 40%;
  } */
  /* .header .left .header-logo {
    padding: 0 25px 15px 0;
  } */

  .header .left .clinical {
    display: inline-block;
    /* padding: 0 15px 15px 15px; */
  }

  .header .left .business-developement {
    display: inline-block;
    /* padding: 0 15px 15px 15px; */
  }

  .header .left .admin {
    display: inline-block;
    /* padding: 0 15px 15px 15px; */
  }

  .header .left .experimental {
    display: inline-block;
    /* padding: 0 0 15px 15px; */
  }

  /* .header .right .healthcare-logo {
    padding: 0 15px 15px 15px;
  } */

  .header .right .notifications-logo {
    padding: 0 15px 15px 15px;
  }

  /* .header .right .profile-logo {
    padding: 0 0 15px 0;
  } */
}
