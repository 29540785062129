.invalid-input {
    border: 1px solid red;
}

.select-drop-down {
    width: 100%;
    text-align: left;
    color: var(--font-color) !important;
    font-family: var(--font-family) !important;
}

.select-drop-down .css-1u9des2-indicatorSeparator {
    display: none;
}

.select-drop-down .css-d7l1ni-option,
.select-drop-down .css-tr4s17-option,
.select-drop-down .css-10wo9uf-option {
    padding: 12px 17px;
    font-size: 14px;
    font-family: var(--font-family);
    word-wrap: break-word;
}

.select-drop-down .react-select__menu {
    z-index: 1050 !important;
    border-radius: none;
    -webkit-box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.26);
    margin-bottom: 0px;
    margin-top: 4px;
}

.select-drop-down .css-1n6sfyn-MenuList {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 14px;
    font-family: var(--font-family);
}

.select-drop-down label {
    font-family: var(--font-family);
}

.select-drop-down .react-select__control,
.select-drop-down .css-t3ipsp-control {
    font-size: 14px;
    font-family: var(--font-family);
    background-color: rgba(var(--primary-color), 0.1);
    /* border: 0px!important; */
    /* border-radius: 0px; */
    padding-top: 5px;
    padding-bottom: 5px;
}

.select-drop-down .css-tj5bde-Svg {
    font-family: var(--font-family);
}

.select-drop-down .css-16xfy0z-control {
    margin: 5px 0px;
}